import axios from 'axios';


export default async function SecondScanAPI(formData, setActiveStep, setLoading, setErrorMessage){


    let formDataJSON = JSON.stringify(formData)
    
    try{
        const response = await axios.post('/api/second-scan', formDataJSON,  {

            headers: {
                'Content-Type': 'application/json',
            },
        });


        if (response.status === 200) {
            setLoading(false)
            setActiveStep(2)
        }
    } catch (error){
        if (error.response.status === 400) {
            const errorMessage = { 
                message: "There is something wrong with your URL",
                statusCode: error.response.status,
                src: error.response.data
            };
            setLoading(false)
            setErrorMessage(errorMessage);
            
        } else {
            const errorMessage = { 
                message: "There is something wrong with your URL",
                statusCode: error.response.status,
                src: error.response.data
            };
            setLoading(false)
            setErrorMessage(errorMessage); 
            
        }
    }
}
