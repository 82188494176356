import { Button } from '@chakra-ui/react'


const SimpleButton = ({ href, result, ...props }) => {
    return (

        <Button
            as="a"
            href={href}
            position={result ? "absolute" : undefined}
            bottom={result ? "0" : undefined}
            mb="20px"
            variant={{base:"blackBoxPhone", xl:"blackBox"}}
            {...props}
        >
            {props.children}
        </Button>
    );
}

export default SimpleButton;

