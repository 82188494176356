const CATEGORIES = [
    {category: 'Arts & Culture'},
    {category: 'Banking/Finance'},
    {category: 'E-commerce'},
    {category: 'Entertainment'},
    {category: 'Environment'},
    {category: 'Fashion'},
    {category: 'Food'},
    {category: 'Health'},
    {category: 'Information Technology'},
    {category: 'Media'},
    {category: 'Retail & Manufacturing'},
    {category: 'Sports'} ,
    {category: 'Travel'} 
]

const CUSTOMERS =[
    {customers: 'Africa', value: 'africa'}, 
    {customers: 'Asia', value: 'asia'},  
    {customers: 'Australia', value: 'australia'},  
    {customers: 'Europe', value:'europe'}, 
    {customers: 'North America', value: 'northAmerica'}, 
    {customers: 'South America', value: 'southAmerica'}, 
    {customers: 'I am not sure', value: 'notSure'},
]

export {CATEGORIES, CUSTOMERS}