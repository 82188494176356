import React from "react";
import { Flex, Box, useMediaQuery } from "@chakra-ui/react"

import Multistep from "./MultistepForm/Multistep";
import Navbar from "../components/Navbar/Navbar";
import BurgerMenu from "../components/BurgerMenu/BurgerMenu";


const HomePage = () => {

  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");

  return (
    <>

      <Box display={{base: "block", lg:"flex"}} height={{lg:"100vh"}} overflow="hidden">

        {isLargerThan960 ? (
          <Flex
            flexShrink={0}
            width={{ lg: "400px", "3xl": "480px" }}
            pl="3%"
            pr="3%"
            borderColor="brand.600"
            borderRightWidth="3px"
            flexDirection="column"
          >

            <Navbar />

          </Flex>

        ) : (
          <Flex justify="end">
            <BurgerMenu />
          </Flex>
        )}

        {/* ####################  RIGHT CONTENT  ####################  */}
        <Box
          flex="1"
          overflowY="auto"
          ml = {{base:'5%', lg:'0%'}}
          mr  = {{base:'5%', lg:'0%'}}
        >
          <Multistep variant="circles" />
        </Box>
      </Box>


    </>
  )

};

export default HomePage;
