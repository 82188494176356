
import {
    Box,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Flex

} from '@chakra-ui/react'

import CustomText from '../CustomText/CustomText'

import "./StepperCustom.css"


const StepperCustom = ({steps, activeStep, setActiveStep }) => {

    return (
        <Stepper
            className="stepper"
            mb={{ base: "1%", "3xl": "5%" }}
            index={activeStep}>
            {steps.map((step, index) => (
                <>
                    <Step
                        className="step"
                        key={index}>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>
                        
                            <Box>
                                <StepTitle>{step.title}</StepTitle>
                                <StepDescription>
                                    <CustomText variant="body" breakpoints={{base:'body12', lg: 'bodySmall', "3xl": 'body' }}>
                                        {step.description}
                                    </CustomText>
                                </StepDescription>
                            </Box>
                    </Step>
                    <StepSeparator width="136px" />
                </>
    ))
}
        </Stepper >
    );

}

export default StepperCustom;
