import React from "react";
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Divider, Text, Image } from '@chakra-ui/react'

import ErrorPage from "./ErrorPage/ErrorPage";
import Loader from "../components/Loader/Loader";

import GetResultsAPI from "../api/GetResultsAPI";

const Results = () => {
    const [resultData, setResultData] = useState([]);
    const publicID = useParams();
    let publicIDString = publicID["id"]

    const [errorMessage, setErrorMessage] = useState(null)

    GetResultsAPI(publicIDString, setResultData, setErrorMessage)

    return (
        <>

            {resultData.length === 0 ? (
                <>
                    <Loader />
                </>


            ) : (
                <>

                    {errorMessage ? (
                        <ErrorPage error={errorMessage} />
                    ) : (
                        <>
                            <Text fontSize='4xl'>User info</Text>
                            <Text>Website url: {resultData.website_url}</Text>
                            <Text>User email: {resultData.user_email}</Text>
                            <Divider />
                            <Text fontSize='4xl'>Banner</Text>
                            <Text>Banner name: {resultData.banner_name}</Text>
                            <Text>Possible to withdraw: {resultData.possible_to_withdraw}</Text>
                            <Text>Image: </Text>
                            <Image src={`http://127.0.0.1:6767/images/banners/${resultData.image_ID}.png`} alt='Banner image' />
                            <Divider />
                            <Text>Scrollable website: {resultData.scrollable_website}</Text>
                            <Divider />
                            <Divider />
                            <Text>Privacy policy: {resultData.privacy_policy}</Text>
                            <Divider />

                            <Divider />
                            <Text>Whistleblowing</Text>
                            <Divider />
                            <Divider />
                            <Text>Cookies before consent</Text>
                            {resultData.cookies_before_consent.map((item, index) => (
                                <Text key={index}>{item}</Text>
                            ))}
                            <Divider />
                            <Text>Unclassified cookies</Text>
                            {resultData.unclassified_cookies.length === 0 ? (
                                <Text>It was not possible to detect unclassified cookies</Text>
                            ) : (
                                <>
                                    {resultData.unclassified_cookies.map((item, index) => (
                                        <Text key={index}>{item}</Text>
                                    ))}
                                </>
                            )}


                            <Divider />
                            <Text>Cookies after reject</Text>
                            {resultData.cookies_after_reject.length === 0 ? (
                                <Text>It was not possible to detect  cookies after reject
                                </Text>
                            ) : (
                                <>
                                    {resultData.cookies_after_reject.map((item, index) => (
                                        <Text key={index}>{item}</Text>
                                    ))}
                                </>
                            )}
                            <Divider />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Results;
