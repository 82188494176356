import './App.css';
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HomePage from './pages/HomePage';
import theme from './theme/theme';
import Fonts from './components/Fonts/Fonts';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Results from './pages/Results'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3624f4442ead972cb5e785efcac93fea@o4506019041116160.ingest.sentry.io/4506019096231936",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
]);



const App = () => {

  const router = createBrowserRouter([
    {
      id: 'root',
      children: [

        { path: "/", element: <HomePage /> },
        { path: "/results/:id", element: <Results /> },
        { path: '*', element: <ErrorPage errorInfo="404" replace /> },

      ]
    },

  ]);




  return (

    <ChakraProvider theme={theme}>
      <Fonts />
      <React.Fragment>
        <RouterProvider router={router} />
      </React.Fragment>
    </ChakraProvider>


  );

}


export default App;
