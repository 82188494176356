import { Flex, Image, Box } from '@chakra-ui/react'
import SimpleButton from '../Button/SimpleButton';
import CustomText from '../CustomText/CustomText'


const BoxResults = ({ data, thirdBox }) => {

    return (
        <Flex
            position="relative"
            flexDirection="column"
            justifyContent="center"
            alignItems={{base:"center", xl:thirdBox === 'yes' ? 'baseline' : 'center', "2xl": "center" }}
            width={{ base:"100%", xl: thirdBox === 'yes' ? '100%' : '48%', "2xl": "412px" }}
            height={{ xl: thirdBox === 'yes' ? '438px' : '613px', "2xl": "613px" }}
            p="22px 41px 22px 41px"
            backgroundColor="brand.800"
            borderRadius="10px"
            marginBottom={{ base: '5%', xl: thirdBox === 'yes' ? '20px' : '0px' }}>

            <Image

                width={{base:"50%",xl:"200px"}}
                src={data.image}
                alt={data.alt}
                margin={{ xl: thirdBox === 'yes' ? '0 auto 0 auto' : '0px', "2xl": "0px" }}
            />
            <CustomText pt={{base:"10px", xl:"34px"}} pb={{base:"10px", xl:"25px"}} variant="bodyBoldedSmall" breakpoints={{ base:'bodyBolded12',xl: 'bodyBoldedSmall', "2xl": 'bodyBoldedSmall' }}>{data.heading}</CustomText>
            <CustomText pb={{base:"10px", xl:"20px"}} variant="body16" breakpoints={{base:'body12', xl: 'body16', "2xl": 'body16' }}>{data.firstParagraph}</CustomText>
            <CustomText pb={{base:"50px", xl:"73px", "2xl":"50px"}} variant="body16" breakpoints={{base:'body12', xl: 'body16', "2xl": 'body16' }}>{data.secondParagraph}</CustomText>

            <SimpleButton href={data.href} result="yes" target="_blank">{data.buttonText}</SimpleButton>
        </Flex>
    )
}


export default BoxResults;


