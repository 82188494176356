import React from "react";
import { Flex, Box, Image, Text, Stack, UnorderedList, ListItem, Link, useMediaQuery } from "@chakra-ui/react"

import SimpleButton from "../Button/SimpleButton";
import CustomText from "../CustomText/CustomText";
import logo from "../../assets/images/logo.svg"
import homeIcon from "../../assets/images/icon-home.svg"
import helpIcon from "../../assets/images/icon-help.svg"

const Navbar = () => {


  return (
    <>
      <Flex flex="1" flexDirection="column">
        {/* ####################  TOP  ####################  */}
        <Stack pt='10%' pb={{ lg: '5%', "3xl": '8%' }}>
          <Link href="https://cookieinformation.com/" isExternal>
            <Image src={logo} alt='Logo' />
          </Link>
          <CustomText variant="bodyBolded" breakpoints={{ base: 'bodyBoldedSmall', "3xl": 'bodyBolded' }}>Compliance check</CustomText>
        </Stack>

        {/* ####################  MIDDLE  ####################  */}
        <Stack>
          <CustomText pb="1%" variant="bodyBolded" breakpoints={{ base: 'bodyBoldedSmall', "3xl": 'bodyBolded' }} >How does it work?</CustomText>
          <CustomText pb="3%" variant="body" breakpoints={{ base: 'bodySmall', "3xl": 'body' }}>Our Compliance Check assesses your website based on the legal requirements of the GDPR and ePrivacy Directive.</CustomText>
          <CustomText pb="3%" variant="body" breakpoints={{ base: 'bodySmall', "3xl": 'body' }}>We check the following:</CustomText>
          <UnorderedList fontSize={{ base: "14px", "3xl": "18px" }}>
            <ListItem>Cookie banner</ListItem>
            <ListItem>Privacy policy</ListItem>
            <ListItem>Cookies set prior to consent</ListItem>
            <ListItem>Unclassified cookies</ListItem>
            <ListItem>Cookies set after consent is rejected</ListItem>
          </UnorderedList>
        </Stack>
      </Flex>
      {/* ####################  BOTTOM  ####################  */}
      <Flex flexDirection="column" >
        <CustomText mt={{ base: '8%', lg: '0%' }} mb="14px" variant="bodyBolded" breakpoints={{ base: 'bodyBoldedSmall', "3xl": 'bodyBolded' }} >Options</CustomText>

        <Flex mb={{ lg: '8px', "3xl": '15px' }}>
          <Image src={homeIcon} alt='Website' mr="7px" />
          <Link href="https://cookieinformation.com/">
            <CustomText variant="bodyNormal" breakpoints={{ base: 'bodySmall', "3xl": 'bodyNormal' }}>Website</CustomText>
          </Link>
        </Flex>

        <Flex mb={{ base: '3%', lg: '8px', "3xl": '15px' }}>
          <Image src={helpIcon} alt='Help' mr="7px" />
          <Link href="https://support.cookieinformation.com/en/" textStyle="bodyNormal">
            <CustomText variant="bodyNormal" breakpoints={{ base: 'bodySmall', "3xl": 'bodyNormal' }}>Help center</CustomText></Link>
        </Flex>


        <Flex display="column">
          <CustomText width={{ base: "100%", lg: "329px", "3xl": "365px" }} variant="bodyBoldedSmall" breakpoints={{ base: 'bodyBolded12', "3xl": 'bodyBoldedSmall' }}>
            Get a free 30-day trial of our proven cookie banner and make privacy your competitive edge.
          </CustomText>
          <SimpleButton mt="10px" width="100%" href="https://cookieinformation.com/registration/" target="_blank">Free trial</SimpleButton>
        </Flex>

      </Flex>


    </>
  )

};

export default Navbar;
