// HubSpotForm.js
import React, { useEffect, useState } from "react";
import { Flex, Box, Text } from '@chakra-ui/react'
import "./HubSpotForm.css";

const HubSpotForm = ({ region, portalId, formId, onSubmit }) => {
  const [isHubSpotFormSubmitted, setIsHubSpotFormSubmitted] = useState(false);
  const [errorWebsite, setErrorWebsite] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",
          onFormSubmit: async ($form) => {
            const website = $form.querySelector('[name="website"]');
            const websiteValue = website ? website.value.trim() : '';

            if (!isValidURL(websiteValue)) {
              setErrorWebsite("Url validation error");
              return;
            }

            setErrorWebsite("");

            const formData = Array.from($form.elements).reduce((data, element) => {
              if (element.name) {
                data[element.name] = element.value;
              }
              return data;
            }, {});

            try {

              await new Promise((resolve) => setTimeout(resolve, 1000));

              setIsHubSpotFormSubmitted(true);
              onSubmit(formData);

            } catch (error) {
              console.error("Error submitting data to HubSpot:", error);
            }
          },
        });
      }
    });
  }, [portalId, formId, region, errorWebsite]);


  function isValidURL(websiteUrl) {
    const websiteRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return websiteRegex.test(websiteUrl);
  }

  useEffect(() => {
    if (isHubSpotFormSubmitted) {
      onSubmit();
    }
  }, [isHubSpotFormSubmitted, onSubmit]);

  return (
    <>
      <Flex direction="column">

        {errorWebsite &&
          <Box mt="3%"
            mb="3%"
            p="1%"
            backgroundColor="#f2c6c6"
            borderColor="#B52B2B"
            borderWidth="2px"
            borderRadius="10px"
          >
            <Flex direction="column" align="center" >
              <Text fontWeight="700">Invalid domain name</Text>
              <Text>Website domain name has to be without https://</Text>
              <Text>ex. cookieinformation.com</Text>
            </Flex>
          </Box>
        }

        <div id="hubspotForm" class="user-info-scanning"></div>



      </Flex>

    </>
  )
};

export default HubSpotForm;
