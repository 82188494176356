import { extendTheme } from "@chakra-ui/react"



const fontSizes = {
    h1: "50px",
    body: "18px",
    label: "16px",
    bodySmall: "14px",
    bodyBolded: "20px",
    bodyNormal: "20px",
    bodyMini: "12px",
    body8: "8px"
}

const customBreakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
  '3xl': '1920px', 
};



const theme = extendTheme({
    breakpoints: customBreakpoints,
    colors: {
        brand: {
            100: "#000", //black
            200: "#fff", //white
            300: "#B52B2B", //red,
            400: "#237AC3", //blue
            500: "#E4E4E4", //gray
            600: "#D9D9D9", //light-gray
            700: "#6D7978", //placeholder-gray
            800: "#F8F8F8" //bg-gray
        }
    },
    fonts: {
        heading: `'Besley', sans-serif`,
        body: `'Jakarta', sans-serif`,
    },
    components: {
        Button: {
          variants: {
            black: {
              bg: "brand.100",
              color: "brand.200",
              height:"60px",
              width:"122px",
              borderRadius:"15px",
              fontSize: "18px"
            },
            blackBox:{
                bg: "brand.100",
                color: "brand.200",
                height:"55px",
                width:"136px",
                borderRadius:"15px",
                fontSize: "16px"
            },
            blackBoxPhone:{
              bg: "brand.100",
              color: "brand.200",
              height:"40px",
              width:"80%",
              borderRadius:"10px",
              fontSize: "16px"
          },
            white: {
              color: "brand.100",
              fontSize: "18px"
            },
            blackPhone:{
              bg: "brand.100",
              color: "brand.200",
              height:"40px",
              width:"90px",
              borderRadius:"6px",
              fontSize: "14px"
            },
            whitePhone: {
              color: "brand.100",
              fontSize: "14px"
            }
          },
        },
      },

},
);

export default theme;