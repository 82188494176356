import React from 'react';
import { Text, useBreakpointValue } from '@chakra-ui/react';

const createTextStyle = (fontSize, fontWeight, letterSpacing, fontStyle, color) => {
    return {
        fontSize,
        fontWeight,
        letterSpacing,
        fontStyle,
        color,
    };
};

const fontSizes = {
    h1: "50px",
    h1Small: "30px",
    body: "18px",
    label: "16px",
    body12: "12px",
    bodySmall: "14px",
    bodyBolded: "20px",
    bodyNormal: "20px",
    body10: "10px"
}

const textStyles = {
    h1: createTextStyle(fontSizes.h1, "900", "-0.55px"),
    h1Small: createTextStyle(fontSizes.h1Small, "900", "-0.55px"),
    body: createTextStyle(fontSizes.body, "normal", "-0.198px"),
    body12: createTextStyle(fontSizes.body12, "normal", "-0.198px"),
    bodyItalic: createTextStyle(fontSizes.bodySmall, "normal", "-0.154px", "italic"),
    bodySmallItalic: createTextStyle(fontSizes.body12, "normal", "-0.154px", "italic"),
    bodySmall10Italic: createTextStyle(fontSizes.body10, "normal", "-0.154px", "italic"),
    bodySmall: createTextStyle(fontSizes.bodySmall, "normal", "-0.22px", null, "#000"),
    bodyNormal: createTextStyle(fontSizes.bodyNormal, "normal", "-0.22px"),
    bodyBolded: createTextStyle(fontSizes.bodyBolded, "900", "-0.22px"),
    bodyBolded12: createTextStyle(fontSizes.body12, "900", "-0.22px"),
    bodyBoldedSmall: createTextStyle(fontSizes.label, "900", "-0.22px"),
    errorMessage: createTextStyle(fontSizes.label, "bold", "-0.22px", null, "#B52B2B"),
    errorMessageMobile: createTextStyle(fontSizes.body12, "bold", "-0.22px", null, "#B52B2B"),
};


const CustomText = ({ variant, breakpoints, ...rest }) => {
    const activeVariant = useBreakpointValue(breakpoints) || variant;
    const activeStyle = textStyles[activeVariant];

    return (
        <Text {...activeStyle} {...rest}>
            {rest.children}
        </Text>
    );
};

export default CustomText