import {Box} from '@chakra-ui/react'

import BoxResults from '../../components/BoxResults/BoxResults'
import CMP from "../../assets/images/graphic/CMP.svg"
import WSB from "../../assets/images/graphic/WSB.svg"
import DSR from "../../assets/images/graphic/DSR.svg"

const ResultsStep = () => {

    const firstBox =  {
        "image" : CMP,
        "alt": "Consent management",
        "href": "https://cookieinformation.com/registration/",
        "heading": "Want to increase preference for your brand by up to 43%?",
        "firstParagraph": "Provide a positive privacy experience through transparent data collection.",
        "secondParagraph": "Cookie Information’s customizable cookie banner ensures your compliance with global privacy laws without compromising your marketing performance. Make privacy your competitive edge.",
        "buttonText": "Free trial"
    }

    const secondBox = {
        "image" : WSB,
        "alt": "Whistleblowing",
        "href": "https://cookieinformation.com/whistleblowing/",
        "heading": "Are you set up to comply with the EU Whistleblower Directive?",
        "firstParagraph": "Ensure your compliance with one of the most privacy-centric reporting channels on the market.",
        "secondParagraph": "Cookie Information’s Whistleblowing guarantees anonymity for whistleblowers and allows them to speak up without fear of retaliation.  Ready-to-go setup, up and running in minutes.",
        "buttonText": "Learn More"
    }

    const thirdBox = {
        "image" : DSR,
        "alt": "Data Subject Request",
        "href": "https://cookieinformation.com/data-subject-request/",
        "heading": "58% of companies don’t use technology to manage their data subject requests (DSR)",
        "firstParagraph": "Increase your efficiency and reduce the cost per request with Cookie Information’s Data Subject Request.",
        "secondParagraph": "It helps you by centralizing your DSR request intake in one platform and automatically finds requested data. Reliably meet the 1-month response deadline with less manual work.",
        "buttonText": "Learn More"
    }


    return (
        <>
            <Box display={{base:"block", xl:"flex"}} gap="10px" flexWrap={{xl:"wrap", "2xl":"nowrap"}} justifyContent={{xl:"space-between", "2xl":"center"}} >
                <BoxResults data = {firstBox} thirdBox="no"/>
                <BoxResults data = {secondBox} thirdBox="no" />
                <BoxResults data = {thirdBox} thirdBox="yes"/>
            </Box>

        </>
    )
}

export default ResultsStep;