import { Flex, Text, Image } from '@chakra-ui/react'

import SimpleButton  from '../../components/Button/SimpleButton'
import Error500 from '../../assets/images/graphic/error500.svg'
import Error404 from '../../assets/images/graphic/error404.svg'
import ErrorResults from '../../assets/images/graphic/errorResults.svg'

export default function ErrorPage({errorInfo}){

    let error = {}
    if(errorInfo==="500"){
        error = {
            image: Error500,
            firstText: "Oops, 500",
            secondText: "There seem to be a problem with the server",
            href: "/",
            cta: "Go back"
        }
    }
    else if(errorInfo === "404"){
        error = {
            image: Error404,
            firstText: "Oops, 404",
            secondText: "We can’t find the page you’re looking for.",
            href: "/",
            cta: "Go back"
        }
    }else{
        error = {
            image: ErrorResults,
            firstText: "Something went wrong…",
            secondText: "Seems like we’ve encountered a technical issue! Please check that your website URL is correct and try again. ",
            href: "/",
            cta: "Restart scan"
        }
    }


    return(
        <>
            <Flex
                justifyContent="center"
                alignItems="center"
                height="100vh"
                flexDirection="column"
                >
                <Image width="40%" mb="40px" src={error.image} />
                <Text mb="10px">{error.firstText}</Text>
                <Text 
                    mb="30px"
                    fontSize={{base:"20px", lg:"40px"}}
                    fontWeight="800"
                    width={{ base: '100%', lg: ["500", "400"].includes(errorInfo) ? '520px' : '60%' }}
                    textAlign="center"
                    >
                    {error.secondText}
                    
                    </Text>
                <SimpleButton href={error.href}>{error.cta}</SimpleButton>
            </Flex>
        </>
    )

}
