import axios from 'axios';


export default async function GetResultsAPI(publicID, setResultData, setErrorMessage) {


    try {
        const response = await axios.get('/api/get-results', {
            params: {
                publicID: publicID,
            }
        });
        if (response.status === 200) {
            const resultData = response.data
            setResultData(resultData)
        }
    } catch (error) {

        let errorMessage = {
            message: "An unknown error occurred",
            statusCode: "UNKNOWN",
            src: 'frontend/src/assets/unknown-error.png'
        };

        if (error.response) {


            if (error.response.status === 500) {
                errorMessage = {
                    message: "Something went wrong",
                    statusCode: error.response.status,
                    src: 'frontend/src/assets/error.png'
                };


            }

            else if (error.request) {
                errorMessage = {
                    message: "Network error",
                    statusCode: "NETWORK",
                    src: 'frontend/src/assets/network-error.png'
                };
            }

            else {
                errorMessage = {
                    message: error.message,
                    statusCode: "CONFIG",
                    src: 'frontend/src/assets/config-error.png'
                };
            }

            setErrorMessage(errorMessage);
        }
    }
}
