import { Global } from '@emotion/react'

import Besley from '../../assets/fonts/Besley-Bold.woff2';
import JakartaBold from '../../assets/fonts/PlusJakartaSans-Bold.woff2';
import JakartaRegular from '../../assets/fonts/PlusJakartaSans-Regular.woff2';

const Fonts = () => (
    <Global
      styles={`
        @font-face {
          font-family: 'Jakarta';
          font-style: normal;
          font-weight: 400;
          src: url(${JakartaRegular}) format('woff2');
        }
        @font-face {
          font-family: 'Besley';
          font-style: bold;
          font-weight: 700;
          src: url(${Besley}) format('woff2');
        }
        `}
    />

    
  )
  
  export default Fonts


