
import { useState, useEffect } from 'react'
import {
    ButtonGroup,
    Flex,
    Text,
    Heading,
    useSteps,
    Stack,
    Box
} from '@chakra-ui/react'


import FirstStep from './FirstStep';
import HubSpotForm from './HubspotForm';

import ErrorPage from '../ErrorPage/ErrorPage';
import Loader from '../../components/Loader/Loader';
import StepperCustom from '../../components/StepperCustom/StepperCustom';
import CustomButton from '../../components/Button/CustomButton';
import ResultsStep from './ResultsStep';

import CustomText from '../../components/CustomText/CustomText';

import { useFormik } from 'formik';
import FirstScanAPI from '../../api/FirstScanAPI'
import { MultiStepClientSchema } from '../../schemas'



export default function Multistep() {
    const [step, setStep] = useState(0)
    const [errorMessage, setErrorMessage] = useState(null)
    const [isRequiredFieldsFilled, setIsRequiredFieldsFilled] = useState(false);
    const [loading, setLoading] = useState(false);
    let stepText = "";
    let headingText = "";

    const steps = [
        { description: 'Website URL', "number": 0 },
        // { description: 'Contact information', "number": 1 },
        { description: 'Confirmation', "number": 2 },
    ]



    const { values, errors, touched, handleBlur, handleChange, setTouched } = useFormik({
        initialValues: {
            website: '',
            category: '',
            customers: [],
        },

        validationSchema: MultiStepClientSchema
    });



    const nextValidateHandler = async () => {
        setTouched({
            website: true,
            category: true,
            customers: true,
        });
    }






    switch (step) {
        case 0:
            headingText = "Website Compliance Check"
            stepText = "Get an overview of potential compliance risks on your website in less than 5 minutes. Simply fill out the fields below and follow the steps to start scanning your website. ";
            break;
        // case 1:
        //     headingText = "Contact information"
        //     stepText = "You’re almost done! Please fill out the form below, so we can send you the scan results.";
        //     break;
        case 1:
            headingText = "You’re all set!"
            stepText = "We’ll send the scan results to the email you provided within a few minutes. Thank you for using our Compliance Check. ";
            break;
    }

    const updateIsRequiredFieldsFilled = (currentStep) => {
        switch (currentStep) {

            case 0:
                // if (errors.website || errors.category || errors.customers || values.website == '' || values.category == '' || values.customers == '') {
                //     setIsRequiredFieldsFilled(false)
                // } else {
                //     setIsRequiredFieldsFilled(true)

                // }
                setIsRequiredFieldsFilled(true)
                break;
            case 1:
                setIsRequiredFieldsFilled(true)
                break;
            // case 2:
            //     setIsRequiredFieldsFilled(true)
            //     break;
        }
    };



    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })


    // ############################### BUTTON FUNCTIONS ###############################
    const handleNext = () => {
        nextValidateHandler();
        if (step < steps.length - 1) {
            setStep(step + 1);
            setActiveStep(step + 1);
        }
    };

    const handleBack = () => {
        if (step > 0) {
            setStep(step - 1);
            setActiveStep(step - 1);
        }
    };

    useEffect(() => {
        updateIsRequiredFieldsFilled(step);
    });


    // ############################### SUBMITING THE DATA ###############################

    const handleHubspotFormSubmit = async (formData) => {
        // let website = values.website;
        setLoading(true);
        // FirstScanAPI(website, formData, step, setStep, setActiveStep, setErrorMessage, setLoading);
        FirstScanAPI(formData, step, setStep, setActiveStep, setErrorMessage, setLoading);


    }

    // ############################### RETURN ###############################
    return (
        <>

            <Stack mr={{ lg: '5%', "3xl": '10%' }} ml={{ lg: '5%', "3xl": '10%' }} height="100%" position="relative">
                {errorMessage ? (
                    <ErrorPage errorInfo="500" />
                ) : (
                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center">

                        {/* ####################    HEADING  ####################  */}
                        {loading ? (
                            <Loader />
                        ) : (
                            <>
                                <Heading as="h1"
                                    fontSize={{ base: '20px', lg: '30px', "3xl": '50px' }}
                                    mt={{ lg: '1%', "3xl": '5%' }}
                                    textAlign="center"
                                >
                                    {headingText}
                                </Heading>
                                <CustomText
                                    variant="body"
                                    breakpoints={{ base: 'bodySmall', "3xl": 'body' }}
                                    mt={{ base: '1%', lg: '2%', "3xl": '2%' }}
                                    mb={{ base: '8%', lg: '2%' }}
                                    mr={{ lg: '10%', "3xl": '20%' }}
                                    ml={{ lg: '10%', "3xl": '20%' }}
                                    textAlign="center">
                                    {stepText}
                                </CustomText>

                                {/* ####################    STEP NUMBERS  ####################  */}

                                <StepperCustom steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />


                                {/* {step === 0 ? (
                                    <FirstStep  values={values} touched={touched} errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
                                   
                                    
                                ) : (
                                    step === 1 ? (
                                        <FourthStep values={values} touched={touched} errors={errors} handleChange={handleChange} onHBSubmit={handleHubspotFormSubmit} />
                                    ) : (
                                        step === 2 ? (
                                            <ResultsStep />
                                        ) : null
                                    )
                                )} */}

                                {step === 0 ? (
                                    <>
                                    <HubSpotForm values={values} touched={touched} errors={errors} handleChange={handleChange} onHBSubmit={handleHubspotFormSubmit} />
  
</>

                                ) : (

                                    step === 1 ? (
                                        <ResultsStep />
                                    ) : null

                                )}


                                {/* ####################  DISCLAIMER  ####################  */}

                                <Stack
                                    mt={{ base: '10%', lg: '8%', "3xl": '10%' }}
                                    width={{ base: "100%", lg: "80%" }}>
                                    <CustomText
                                        pb={{ base: '3%', "3xl": '20px' }}
                                        mb={{base:'5%', lg:"0%"}}
                                        variant="bodySmallItalic"
                                        breakpoints={{ base: 'bodySmall10Italic', lg: 'bodySmallItalic', "3xl": 'bodyItalic' }}
                                        display={step === 0 ? "block" : "none"}>
                                        Disclaimer: The results of your Compliance Check are intended to serve as a starting point for identifying
                                        potential compliance risks. The results presented might not be 100% complete and should not be
                                        considered valid legal advice. Cookie Information does not assume any liability for the accuracy and
                                        completeness of the results. For legal advice, please consult a professional lawyer.
                                    </CustomText>

                                    {/* ####################  BUTTONS  #################### 
                                    <ButtonGroup
                                        w="100%"
                                        borderColor="brand.600"
                                        borderTopWidth={step === 2 ? "0px" : "3px"}>
                                        <Flex
                                            align="center"
                                            w="100%"
                                            mt={{ lg: '10px', "3xl": '17px' }}
                                            justifyContent={step === 0 ? "flex-end" : "space-between"}
                                        >
                                            {step === 3 ? (
                                                <CustomButton
                                                    direction="back"
                                                    onClick={handleBack}
                                                    step={step} />
                                            ) : (
                                                <>
                                                    {step !== 2 && (
                                                        <>
                                                            {step !== 0 && (
                                                                <CustomButton
                                                                    direction="back"
                                                                    onClick={handleBack}
                                                                    step={step} />
                                                            )}
                                                            {step !== 1 && (
                                                                <CustomButton
                                                                    direction="next"
                                                                    isDisabled={!isRequiredFieldsFilled}
                                                                    onClick={handleNext}
                                                                />
                                                            )}



                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Flex>

                                    </ButtonGroup> */}
                                </Stack>
                            </>
                        )}
                    </Flex>
                )}
            </Stack>
        </>

    );





}