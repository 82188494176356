import React, { useEffect } from 'react';
import {Flex} from '@chakra-ui/react';
import HubSpotForm from '../../components/HubSpotFrom/HubSpotForm';

const FourthStep = ({ values, touched, errors, handleChange, onHBSubmit }) => {

  const handleFormSubmit = (formData) => {
    let formDataSubmit = { ...values, ...formData };
    onHBSubmit(formDataSubmit);
  };

    



  return (
    <>
      <Flex mt={{base:"10%", xl:"0%"}}>

      {/* 
      FORM WITH ALL THE FIELDS
      <HubSpotForm
        portalId='5354868'
        formId='1573dee5-b1e7-4ed5-8d1d-eb9061965ef4'
        onSubmit={handleFormSubmit}
        loading={<div>Loading...</div>}
      /> */}

<HubSpotForm
        portalId='5354868'
        formId='37c07951-51fd-40da-9bcc-156654fde5ec'
        onSubmit={handleFormSubmit}
        loading={<div>Loading...</div>}
      />
      </Flex>


    </>
  )
}

export default FourthStep;