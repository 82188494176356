import axios from 'axios';
import SecondScanAPI from './SecondScanAPI';


export default async function FirstScanAPI(formData,step, setStep, setActiveStep, setErrorMessage,setLoading){

    try{
        const response = await axios.get('/api/first-scan', { params:{
            websiteURL: formData.website,
        }});
        if (response.status === 200) {
            setLoading(false)
            setStep(step+1)
            setActiveStep(step+1)
            SecondScanAPI(formData, setActiveStep, setLoading, setErrorMessage)
        }
    } catch (error){
        if (error.response.status === 400) {
            const errorMessage = { 
                message: "There is something wrong with your URL",
                statusCode: error.response.status,
                src: error.response.data
            };
            setErrorMessage(errorMessage);
            setLoading(false)
        } else {
            const errorMessage = { 
                message: "Something went wrong",
                statusCode: error.response.status,
                src: 'frontend/src/assets/error.png'
            };
            setErrorMessage(errorMessage);
            setLoading(false)
        }
    }
}
