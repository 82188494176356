import * as Yup from 'yup';
import { CATEGORIES, CUSTOMERS } from '../util/Constants';

const regexURL = /^((?!https?:\/\/)[\w.-]+\.[a-zA-Z]{2,})$/;
const regexDSR = /^(?!https?:\/\/)app\.termly\.io\/notify\/[a-zA-Z0-9-]+$/



// const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const MultiStepClientSchema = Yup.object().shape({
    website: Yup.string()
        .required('Please type in your URL')
        .matches(regexURL, { message: "This website is a wrong format. Correct format: www.cookieinformation.com" }),
    category: Yup.mixed()
        .required('Please select a category from the list.')
        .oneOf(
            CATEGORIES.map((option) => option.category),
            'Invalid choice.'
        ),
    customers: Yup.array()
        .min(1, 'Please select a location from the list.')
        .of(Yup.string().oneOf(CUSTOMERS.map(option => option.value), 'Invalid choice'))


    // dsr: Yup.string()
    //     .matches(regexDSR, { message: "The URL format is wrong, example: www.cookieinformation.com/cookie-and-privacy-policy/" }),


})


