import { Flex, Heading, Text, Container } from '@chakra-ui/react'
// --------------------------
// STYLES ---------------
// --------------------------
import "./Loader.css"

const Loader = () => {

  return (

    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt="5%"
      >
        <Heading as="h1" textStyle="h1" fontSize="50px" mb="30px">Scanning your website…</Heading>
        <Text>Sit tight, it might take a moment. </Text>

        <Container
          textAlign="center"
          mt="40%">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        </Container>
      </Flex>
    </>

  );
};

export default Loader;
